@font-face {
  font-family: "Noto Color Emoji";
  src: url(/fonts/NotoColorEmoji.ttf);
}

$font-family-sans-serif: "Calibri", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Noto Color Emoji", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

$light: #e9ecef;

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../node_modules/@contagt/bootstrap-select/sass/bootstrap-select.scss";
@import "../../../node_modules/react-toastify/dist/ReactToastify.css";

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: black;
}

html,
body {
  height: 100%;
}

.modal-custom-fullscreen {
  max-width: 95%;
  height: 95%;
  margin: 15px auto;
  .modal-content {
    height: 100%;
  }
}

.table-custom-double-striped {
  tbody tr:nth-of-type(4n + 3),
  tbody tr:nth-of-type(4n) {
    background-color: rgba(0, 0, 0, 0);
  }
  tbody tr:nth-of-type(4n + 1),
  tbody tr:nth-of-type(4n + 2) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table-custom-triple-striped {
  tbody tr:nth-of-type(6n + 4),
  tbody tr:nth-of-type(6n + 5),
  tbody tr:nth-of-type(6n) {
    background-color: rgba(0, 0, 0, 0);
  }
  tbody tr:nth-of-type(6n + 1),
  tbody tr:nth-of-type(6n + 2),
  tbody tr:nth-of-type(6n + 3) {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table-custom-extra-padding {
  td {
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }
}

.checkbox-custom {
  display: none;
}
.checkbox-custom ~ span:before {
  content: "✖ ";
}
.checkbox-custom:checked ~ span:before {
  content: "✔ ";
}

.bg-custom-1 {
  background-color: #0062cc;
  background-image: url("/images/bg-custom-1.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bg-custom-2 {
  background-color: black;
  color: white;
  background-image: url("/images/bg-custom-2.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.bg-custom-3 {
  background-color: blue;
  color: white;
  background-image: url("/images/bg-custom-3.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.custom-modal-header {
  justify-content: flex-end;
  .custom-modal-header-link {
    cursor: pointer;
    font-size: 1.5rem;
    opacity: 0.5;
    line-height: 1;
    text-decoration: none;
    &:hover {
      opacity: 1;
    }
  }
  .close {
    padding: 0;
    margin: 0;
  }
}

.custom-user-text {
  overflow-wrap: break-word;
}

.inside-iframe {
  .not-iframe {
    display: none;
  }
}

.card-custom-system {
  background: #e1f3fb;
}

.card-custom-outgoing {
  background: #dcf8c6;
}

.nobr {
  white-space: nowrap;
}

.thead-dark,
.table-dark {
  a {
    color: white;
  }
}

.custom-comms-background {
  background: #e4ddd5;
  background: #e6d3bb url("/images/natural-paper.png");
  /* transparenttextures.com */
}

@media (min-width: 768px) {
  .custom-gutter > [class*="col-"] {
    padding-right: 4px;
    padding-left: 4px;
  }
  .custom-gutter > [class*="col-"]:first-child {
    padding-left: 15px;
  }
  .custom-gutter > [class*="col-"]:last-child {
    padding-right: 15px;
  }
}
.scrollBottom {
  position: fixed;
  display: block;
  bottom: 3rem;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  border-radius: 1.5rem;
  background-image: url(/images/arrow-bottom.png);
  background-color: rgba(255, 255, 255, 0.75);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2rem auto;
  transition: transform 500ms cubic-bezier(0.23, 0.73, 0.42, 1.56);
  &.flip {
    transform: rotate(180deg);
  }
}

*[title] {
  text-decoration: underline currentColor dotted;
}

.bootstrap-select .btn {
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;
}

.custom-bg-green-flash {
  animation: bgGreenFlash 0.5s forwards linear normal;
  animation-iteration-count: infinite;
}

.custom-navbar-multiline {
  .nav-link {
    display: inline-block;
  }
  .navbar-nav {
    flex-wrap: wrap;
  }
  @include media-breakpoint-down(md) {
    .dropdown-menu {
      display: block;
    }
    .dropdown-toggle {
      display: none;
    }
  }
  @include media-breakpoint-up(lg) {
    .custom-flex-right {
      flex: 1;
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

.custom-navbar-subgroup-wrapper {
  position: relative;
  .custom-navbar-subgroup {
    position: absolute;
  }
  @include media-breakpoint-down(md) {
    margin-left: 0.75rem;
    .custom-navbar-subgroup {
      left: -0.75rem;
      top: 0;
      height: 100%;
      width: 0.25rem;
    }
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: 0.25rem;
    .custom-navbar-subgroup {
      bottom: -0.25rem;
      left: 0;
      width: 100%;
      height: 0.25rem;
    }
  }
}

.custom-file-upload {
  input {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

@keyframes bgGreenFlash {
  0% {
    background: green;
  }
  50% {
    background: none;
  }
}

.table-fixed-head {
  overflow: auto;
  height: 100px;
}
.table-fixed-head thead {
  position: sticky;
  top: 0;
  z-index: 5;
}

.sprite {
  width: 1em;
  // padding-bottom: 100%;
  padding-bottom: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100em 1em;
}

.sprite64 {
  @extend .sprite;
  font-size: 64px;
}

.sprite96 {
  @extend .sprite;
  font-size: 96px;
}

.sprite128 {
  @extend .sprite;
  font-size: 128px;
}

// .image {
//   padding-bottom: 100%;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: contain;
// }

// .image-top {
//   @extend .image;
//   background-position-y: top;
// }

.chat-padding {
  padding: 0.5rem;
  @include media-breakpoint-up(sm) {
    padding: 1rem;
  }
};

#chat {
  // min-height: 40em;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  &.fixed {
    min-height: none;
    @extend .rounded;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      width: 20rem;
      top: 0.5rem;
      bottom: 0.5rem;
      right: 0.5rem;
      border: 1px solid $dark;
    };
    .reply-box {
      button {
        @extend .rounded-circle;
        padding: 0;
        flex: 0 0 3em;
        &:before {
          content: '✉'
        }
      }
    }
  }
  .chat-container {
    // // display: flex;
    // // flex-direction: row;
    // // width: 100%;
    // // flex-grow: 1;
    // // align-items: stretch;
    // @extend .flex-grow-1;
    // @extend .flex-shrink-0;
    // @extend .d-flex;
    // @extend .flex-column;
    width: 100%;
    display: flex;
    // flex-direction: row;
    flex: 1 0 0;
    overflow-y: auto; 
  }
  .chatroom-list-container, .chatroom-list, .chatroom {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
    width: 100%;
    overflow: auto;
  };
  .chatroom-list {
    list-style: none;
    padding: 0;
    & > li {
      border-bottom: 1px solid black;
      p {
        margin-bottom: 0;
      }
      cursor: pointer;
      &.locked {
        color: gray;
      };
      &.unseen {
        font-weight: 700;
      };
      &.answered {
        font-style: italic;
      }
    };
  };
  .message-list {
    @extend .chat-padding;
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column-reverse;
    flex-grow: 1;
    overflow: auto;
    .files {
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: .25em;
      & > * {
        word-break: break-all;
      }
    };
  };
  .message-incoming, .message-outgoing, .message-other {
    @extend .rounded;
    @extend .shadow-sm;
    @extend .border-0;
    flex: 0 1 auto;
    // white-space: pre-wrap;
    @extend .chat-padding;
    .body p {
      margin: 0 0 0.25rem;
    }
    @include media-breakpoint-up(sm) {
      max-width: 80%;
      min-width: 30%;
    };
    .foot {
      @extend .small;
      display: flex;
      justify-content: space-between;
    }
  };
  .message-incoming:not(:first-child),
  .message-outgoing:not(:first-child),
  .message-other:not(:first-child) {
    @extend .mb-1;
  };
  .message-incoming + .message-outgoing,
  .message-incoming + .message-other,
  .message-outgoing + .message-incoming,
  .message-outgoing + .message-other,
  .message-other + .message-outgoing,
  .message-other + .message-incoming {
    @extend .mb-4;
  };
  .message-incoming {
    @extend .align-self-start;
    background-color: #d3defd;
  };
  .message-outgoing {
    @extend .align-self-end;
    background-color: #d9fdd3;
  };
  .message-other {
    @extend .align-self-center;
    background-color: #ebebeb;
  };
  .reply-box {
    @extend .chat-padding;
    .files {
      @extend .mb-2;
      list-style: none;
      padding: 0;
      display: flex;
      flex-flow: row;
      flex-wrap: wrap;
      gap: .25em;
      & > * {
        @extend .rounded;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        &::before, &::after {
          z-index: 1;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          text-align: center;
        }
        &::before {
          font-size: 200%;
        }
        &:hover {
          &::after {
            background: black;
            content: '❌';
            opacity: 0.5;
            text-align: right;
          }
        }
      }
      & > .img {
        height: 4em;
        img {
          height: 100%;
        }
      }
      & > .doc {
        overflow: hidden;
        max-width: 30%;
        word-break: break-all;
        width: 4em;
        height: 4em;
        &::before {
          content: '📄';
        }
      }
    }
    form {
      display: flex;
      flex-direction: row;
      // input[type='upload'] {
      //   @extend .form-control;
      //   @extend .mb-2;
      //   flex: 1 0 100%;
      // }
      & > * {
        margin: 0;
      };
      gap: 0.25em;
      textarea {
        @extend .form-control;
        flex: 1 1 auto;
        min-height: 3em;
        max-height: 20em;
      };
      button, label {
        height: 3em;
        align-self: center;
      };
      label {
        @extend .btn;
        @extend .btn-primary;
      };
      button {
        &:before {
          content: 'Отправить';
        }
      };
    }
  };
  .spinner-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  };
}